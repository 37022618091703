import Layout from "fragments/layout/layout";
import { Hero } from "./components/hero";
import Searchbar from "components/searchEngine";
import { Container } from "components/container";
import { Incentives } from "./components/incentives";
import { HotDestinations } from "components/hotDestinations";
import { HotAirlines } from "components/hotAirlines";
import { Newsletter } from "components/newsletter";

export default function Home() {
  return (
    <Layout>
      <div className="w-full flex justify-center bg-wite">
        <div className="flex flex-col gap-14 w-full">
          <Hero />
          <Container className="text-center my-12">
            <h1 className="w-full mb-4 text-2xl text-center font-bold tracking-tight text-gray-900 sm:text-4xl lg:col-span-2 xl:col-auto">
              Search Your Next
              <span className="text-primary font-bold"> Destination</span>
            </h1>
          <Searchbar />
          </Container>
          <HotDestinations />
          <Incentives />
          <HotAirlines />
          <Newsletter />
        </div>
      </div>
    </Layout>
  );
}
